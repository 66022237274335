import {
  login,
  // token,
  createAccount,
  resendRequest,
  // resetUserPassword,
} from "../../api/account";
import axios from "axios";

import storage from "../storage";

// Ajax wrappers
import { avatar, user } from "./users";
import {
  //document,
  meeting,
  topic,
  //supportingItem,
  invitee,
} from "./meeting";
import { objective, note } from "./objectives";
import { notification } from "./notifications";

let tokenData = null;
// set default header
axios.defaults.headers.common["Authorization"] = getLocalToken();



if (storage.token.access) {
  try {
    tokenData = JSON.parse(window.atob(storage.token.access.split(".")[1]));
  } catch (err) {
    console.warn(err);
  }
}



function getLocalToken() {
  let token = window.localStorage.getItem('accessToken');

  token = "Bearer " + token;
  return token;
}

function setAuthHeader() {
  const token = getLocalToken()
  axios.defaults.headers.common["Authorization"] = token;
  return token;
}

axios.interceptors.response.use((res) => {
  return res;
},
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return axios.get("/api/v1/authentication/refresh")
        .then((res) => {
          if (res.status === 200) {
            window.localStorage.setItem('accessToken', res.data.token)
            originalRequest.headers.Authorization = getLocalToken();
            axios.defaults.headers.common["Authorization"] = getLocalToken();
            return axios(originalRequest);
          }
        }).catch((error) => {
          storage.logout();
          window.location.pathname = "/login";
        })
    } else {
      throw Promise.reject(error.response);
    }
  }
);

export default {
  login,
  // token,
  //newAccount,
  //organization,
  //department,
  document,
  meeting,
  topic,
  //supportingItem,
  objective,
  note,
  user,
  avatar,
  notification,
  resendRequest,
  // resetUserPassword, not used
  getLocalToken,
  setAuthHeader,
};

export {
  login,
  // token,
  createAccount,
  //organization,
  // department,
  //document,
  meeting,
  topic,
  //supportingItem,
  objective,
  note,
  user,
  avatar,
  invitee,
  notification,
  resendRequest,
  getLocalToken,
  setAuthHeader,
};
