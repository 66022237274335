// import { useRoutes } from 'react-router-dom';

import { Route, Routes } from 'react-router-dom';
import React, { Suspense } from 'react';
import Login from 'pages/Login';
import CreateAccount from 'pages/Login/CreateAccount';
import ForgotPassword from 'pages/Login/ForgotPassword';
import ChangePassword from 'pages/Login/ChangePassword';
import Loading from 'components/ui/atoms/Loading';
import NotFound from 'pages/NotFound';
const AuthenticatedRoutes = React.lazy(() => import('./authenticated'));
const AuthRoutes = () => {
    // const auth = useAuth();

    // const commonRoutes = [{ path: '/', element: <Landing /> }];

    // const routes = auth.user ? protectedRoutes : publicRoutes;

    // const element = useRoutes([...routes, ...commonRoutes]);
    //  maybe layout here?
    return (
        <Routes>
            {/* <RouteWrapper path="/login" exact component={Login} template="main" /> */}

            <Route index path={`/login`} element={<Login />} />
            <Route path={`/create-account`} element={<CreateAccount />} />
            <Route path={`/forgot-password`} element={<ForgotPassword />} />
            <Route path="/reset/:token" element={<ChangePassword />} />
            <Route path={"/*"} element={<Suspense fallback={<Loading size={48} />}>
                <AuthenticatedRoutes />
            </Suspense>}>

            </Route>
        </Routes >
    );
};
export default AuthRoutes;
