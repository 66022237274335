import axios from "axios";
import { Task } from "features/meetings/_old/api/types";
import { Moment } from "moment";
import { IRepeat } from 'features/meetings/types';

const HOST = "/api/v1";

// **************    DEPRECATED SEE Meetings/api/meeting    ******************

function url() {
  return HOST;
}

export type Agenda = {
  _id: string;
  createdOn: Moment;
  comments: string[];
  attachments: string[];
  type: "Topic";
  doc: string;
  ownerID: string;
  discussed: boolean;
};

export type Invitee = {
  _id?: string;
  requestStatus: string; // TODO: Create type
  userID: string;
  isAgreed?: boolean;
  userName: string;
};

export type Meeting = {
  _id: string;
  createDate: Moment;
  location: string;
  // meetingNotesConfirmationSent: boolean;
  objectives: Task[];
  // oldMeetings: string[];
  startTime?: Moment;
  endTime?: Moment;
  startDate: Moment;
  endDate: Moment;
  invitees: Invitee[];
  status: "Ended" | "Cancelled" | "Pending" | "InProgress" | "Completed";
  title: string;
  organizationID: string;
  ownerID: string;
  followUpMeetingId?: string,
  agendas: Agenda[];
  repeat: IRepeat
};

function meetingUrl(meetingID?: string | null) {
  return `${url()}/meetings${meetingID ? `/${meetingID}` : ""}`;
}



function topicUrl(meetingID: string, topicID?: string) {
  return `${url()}/meetings/${meetingID}/agendas${topicID ? `/${topicID}` : ""
    }`;
}

function supportingItemUrl(
  meetingID: string,
  topicID: string,
  supportingItemID: string
) {
  return `${url()}/meetings/${meetingID}/agendas/${topicID}/supportingItems${supportingItemID ? `/${supportingItemID}` : ""
    }`;
}

function inviteesUrl(meetingID: string, inviteeID?: string | null) {
  return `${url()}/meetings/${meetingID}/invitees${inviteeID ? `/${inviteeID}` : ""
    }`;
}

function meetingEndConfirmUrl(meetingID: string, memberId: string) {
  return `${url()}/meetings/${meetingID}/members/${memberId}`;
}

function documentUrl(docID: string) {
  return `${url()}/document${docID ? `/${docID}` : ""}`;
}

export const meeting = {
  /**
   *
   * @param oldMeetingId
   * @param {object} meeting
   * @returns {Promise<AxiosPromise<any>>}
   */
  create: async (oldMeetingId: string | null | undefined, meeting: Meeting) => {
    return axios.post(meetingUrl(), { oldMeetingId, meeting });
  },

  /**
   *
   * @param {ObjectId} [meetingID]
   * @returns {Promise<AxiosPromise<any>>}
   */
  get: async (meetingID: string) => {
    return axios.get(meetingUrl(meetingID));
  },

  /**
   *
   * @param {object} meeting
   * @returns {Promise<AxiosPromise<any>>}
   */
  update: async (meeting: Meeting) => {
    return axios.put(meetingUrl(meeting._id), meeting);
  },



  /**
   *
   * @param {object} meeting
   * @returns {Promise<AxiosPromise>}
   */
  remove: async (meeting: Meeting) => {
    return axios.delete(meetingUrl(meeting._id));
  },
};

export const topic = {
  /**
   *
   * @param {object} topic
   * @returns {Promise<AxiosPromise<any>>}
   */
  create: async (meetingID: string, topic: Agenda) => {
    return axios.post(topicUrl(meetingID), topic);
  },

  /**
   *
   * @param {ObjectId} [meetingID]
   * @returns {Promise<AxiosPromise<any>>}
   */
  get: async (meetingID: string) => {
    return axios.get(topicUrl(meetingID));
  },

  /**
   * This is a patch operation
   * @param {object} topic
   * @returns {Promise<AxiosPromise<any>>}
   */
  // update: async (meetingID: string, topicID: string, ops) => {
  //   return axios.patch(topicUrl(meetingID, topicID), ops);
  // },

  /**
   *6
   * @param {object} topic
   * @returns {Promise<AxiosPromise>}
   */
  remove: async (meetingID: string, topicID: string) => {
    return axios.delete(topicUrl(meetingID, topicID));
  },
};


export const invitee = {
  /**
   *
   * @param {object} invitees
   * @returns {Promise<AxiosPromise<any>>}
   */
  create: async (meetingID: string, newInvitees: Invitee[]) => {
    return axios.post(inviteesUrl(meetingID), newInvitees);
  },

  /**
   *
   * @param {object} invitees
   * @returns {Promise<AxiosPromise>}
   */
  remove: async (meetingID: string, inviteeID: string) => {
    return axios.delete(inviteesUrl(meetingID, inviteeID));
  },

  /**
   *
   * @param {object} invitees
   * @returns {Promise<AxiosPromise>}
   */
  updateStatus: async (
    meetingID: string,
    inviteeID: string,
    requestStatus: string
  ) => {
    return axios.patch(inviteesUrl(meetingID, inviteeID), { requestStatus });
  },
  /**
   *
   * @param {object} invitees
   * @returns {Promise<AxiosPromise>}
   */
  confirmMeetingEnd: async (
    meetingID: string,
    inviteeID: string,
    isAgreed: boolean
  ) => {
    return axios.put(
      `${meetingEndConfirmUrl(meetingID, inviteeID)}/isMeetingEndAgreed`,
      { isAgreed }
    );
  }
};
