import io, { ManagerOptions, Socket, SocketOptions } from "socket.io-client";

let socket

export const getSocketInstance = (): Socket => {
    if (!socket) {
        const isProduction = window.location.hostname === "infange.com" || window.location.hostname === "10.0.9.4";
        const port = isProduction ? 443 : 9003;
        const socketUrl = `${window.location.protocol}//${window.location.hostname}:${port}`;
        const options: Partial<ManagerOptions & SocketOptions> = {
            autoConnect: true,
            withCredentials: true,
            path: "/socket"
        };
        socket = io(socketUrl, options);
    }
    return socket;
};


// this will need to be handled
export default getSocketInstance()
