//@ts-nocheck
import axios from 'axios';

const v = {};
export default {
    get access() {
        return window.localStorage.getItem('accessToken');
    },
    get clientID() {
        if (!v.clientID) {
            v.clientID = window.localStorage.getItem('clientID');
        }
        return v.clientID;
    },
    set access(token) {
        window.localStorage.removeItem('accessToken');
        let t = JSON.parse(window.atob(token.split('.')[1]));
        window.localStorage.setItem('accessToken', token);
        window.localStorage.setItem('clientID', t.sub);
        window.localStorage.setItem('profiles', JSON.stringify([t.data]));

        delete v.accessToken;
        delete v.clientID;
        delete v.profiles;
    },

    get profiles() {
        try {
            if (!v.profiles) {
                v.profiles = JSON.parse(window.localStorage.getItem('profiles')) || [];
            }
            return v.profiles;
        } catch (err) {
            console.warn(err);
        }
        return [];
    },
    get refresh() {
        if (!v.refreshToken) {
            v.refreshToken = window.localStorage.getItem('refreshToken');
        }
        return v.refreshToken;
    },

    set refresh(token) {
        window.localStorage.setItem('refreshToken', token);
        delete v.refreshToken;
    },
    invalidate() {
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('refreshToken');
        window.localStorage.removeItem('clientID');
        window.localStorage.removeItem('profiles');
        delete axios.defaults.headers.common['Authorization'];

        // reset the cached values in v
        delete v.accessToken;
        delete v.refreshToken;
        delete v.clientID;
        delete v.profiles;
    }
};
