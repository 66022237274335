import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import TextField from 'components/ui/molecules/TextField';
import { mediumRegex } from '../../../_old/helpers/validation';
import { useForm } from 'react-hook-form';
import Button from 'components/ui/molecules/Button';

type ChangePasswordFormProps = {
    loading: boolean;
    onSubmit: (t: any) => Promise<void>;
};

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({ loading, onSubmit }) => {
    const { handleSubmit, getValues, formState: { errors }, control } = useForm<{ password: string; confirm: string }>();
    return (
        <form id={'change-password'} onSubmit={handleSubmit(onSubmit)}>
            <Grid item>
                <Box paddingBottom={1} textAlign="center" marginBottom={'1.5rem'}>
                    <Box display={'inline-block'}>
                        <Typography color="secondary" align="center" style={{ fontSize: '1.75rem', fontWeight: '600' }}>
                            Change your password
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item>
                <Box height={'4.6rem'}>
                    <TextField
                        fieldType={'password'}
                        placeholder={'Create Password'}
                        name={'password'}
                        id={'password'}
                        control={control}
                        rules={{
                            required: 'Password required',
                            minLength: 7,
                            validate: (v) => mediumRegex.test(v)
                        }}
                        error={Boolean(errors.password)}
                        helperText={errors.password?.message || (errors.password?.type && 'Password must be at least 7 characters and must include a number') || ' '}
                        fullWidth
                    />
                </Box>
            </Grid>
            <Grid item>
                <Box height={'4.6rem'}>
                    <TextField
                        fieldType={'password'}
                        placeholder={'Confirm Password'}
                        name={'confirm'}
                        id={'confirm'}
                        control={control}
                        rules={{
                            required: 'Confirm Password required',
                            minLength: 7,
                            validate: (v) => v === getValues().password
                        }}
                        error={Boolean(errors.confirm)}
                        helperText={errors.confirm?.message || (errors.confirm?.type && 'Password does not match') || ' '}
                        fullWidth
                    />
                </Box>
            </Grid>
            <Grid item>
                <Box textAlign="center" padding={'1rem'}>
                    <Button style={{ maxWidth: '90%' }} loading={loading} variant={'contained'} color={'primary'} type={'submit'}>
                        <Box width={'18rem'} style={{ maxWidth: '90%' }}>
                            <Typography style={{ fontSize: '1.5rem', textTransform: 'none' }}>Submit</Typography>
                        </Box>
                    </Button>
                </Box>
            </Grid>
        </form>
    );
};

export default ChangePasswordForm;
