import { useEffect, useRef, useState } from 'react';
import storage from '_old/helpers/storage';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { hideSignedOutAlert, showSignedOutAlert } from 'store/slices/inactivitySlice';
import { RootState } from 'store/reducers';

// Duration constants
const TRIGGER_WARNINGS = {
    SIX_HOURS: 6 * 60 * 60 * 1000, // 6 hours of inactivity before warning is shown
    FIVE_SECONDS: 3 * 1000, // 5 seconds of inactivity before warning is shown
    ONE_MINUTE: 1 * 60 * 1000 // 1 minute of inactivity before warning is shown
};

const TRIGGER_COUNTDOWN = {
    ONE_MINUTE: 60, // 1 minute of inactivity before user is logged out
    TEN_SECONDS: 10, // 10 seconds of inactivity before user is logged out
    FIVE_SECONDS: 5 // 5 seconds of inactivity before user is logged out
};

const InactivityListener = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [countdown, setCountdown] = useState(5);

    // Refs for timeouts and intervals to detect inactivity
    const inactivityTimeout = useRef<any>(undefined);
    const countdownInterval = useRef<any>(undefined);

    // Hooks for dispatching and selecting from Redux
    const dispatch = useDispatch();
    const isAlertOpen = useSelector<RootState, boolean>((state) => state.inactivity.isAlertOpen); // Access the slice from the global state

    // Function to logout the user and redirect to login page
    const handleLogout = () => {
        setOpen(false);
        storage.logout();
        if (navigate) navigate('/login');

        dispatch(showSignedOutAlert());
    };

    // Function to close the warning snackbar
    const handleClose = () => {
        setOpen(false);
        resetTimer();
    };
    // Function to close the session expired alert snackbar
    const handleSignedOutAlertClose = () => {
        dispatch(hideSignedOutAlert());
    };

    // Show the warning snackbar and start the countdown
    const showWarning = () => {
        setOpen(true);
        countdownInterval.current = setInterval(() => {
            setCountdown((prev) => {
                if (prev <= 1) {
                    clearInterval(countdownInterval.current);
                    handleLogout();
                }
                return prev - 1;
            });
        }, 1000);
    };

    // Reset the inactivity and countdown timers
    const resetTimer = () => {
        if (inactivityTimeout.current) clearTimeout(inactivityTimeout.current);
        if (countdownInterval.current) clearInterval(countdownInterval.current);
        setOpen(false); // Hide the snackbar when user becomes active
        // Delay the update of the countdown state
        setTimeout(() => {
            setCountdown(TRIGGER_COUNTDOWN.ONE_MINUTE);
        }, 250); // slightly longer than the default Snackbar exit transition duration
        inactivityTimeout.current = setTimeout(showWarning, TRIGGER_WARNINGS.SIX_HOURS);
    };

    // Attach event listeners for inactivity detection
    useEffect(() => {
        if (location.pathname !== '/login') {
            document.addEventListener('mousemove', resetTimer);
            document.addEventListener('keypress', resetTimer);
        }

        // Cleanup
        return () => {
            document.removeEventListener('mousemove', resetTimer);
            document.removeEventListener('keypress', resetTimer);

            if (inactivityTimeout.current) clearTimeout(inactivityTimeout.current);
            if (countdownInterval.current) clearInterval(countdownInterval.current);
        };
    }, []);

    return (
        <>
            {!isAlertOpen ? (
                <Snackbar open={open} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert
                        severity="warning"
                        action={
                            <IconButton color="inherit" size="small" onClick={handleClose}>
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }>
                        You will be logged out in {countdown} seconds due to inactivity.
                    </Alert>
                </Snackbar>
            ) : (
                <Snackbar open={isAlertOpen} onClose={handleSignedOutAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert
                        severity="warning"
                        action={
                            <IconButton color="inherit" size="small" onClick={handleSignedOutAlertClose}>
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }>
                        Your session has expired due to inactivity. Please sign in to continue.
                    </Alert>
                </Snackbar>
            )}
        </>
    );
};

export default InactivityListener;
