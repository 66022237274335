import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

type UserIconProps = {
    size: number;
};
export const UserIcon: React.FC<UserIconProps> = ({ size = 2 }) => {
    return (
        <SvgIcon
            viewBox="0 0 100 100"
            fill="none"
            stroke="#FF7315"
            strokeWidth="10"
            sx={{
                position: 'relative',
                display: 'inline-block',
                width: size.toString() + 'rem',
                height: size.toString() + 'rem',
                zIndex: '1000',
                fill: 'none'
            }}>
            <circle cx="50" cy="30" r="25" />
            <circle cx="50" cy="100" r="45" />
        </SvgIcon>
    );
};
