import React from 'react';
import CircularLoading, { CircularProgressProps } from '@mui/material/CircularProgress';


type Props = CircularProgressProps & {
    size?: number;
    noStyles?: boolean;
};

const Loading: React.FC<Props> = ({ noStyles, color = 'primary', size = 36, ...props }) => {
    return !noStyles ? (
        <CircularLoading
            color={color}
            size={size}
            {...props}
            sx={{
                top: `calc(50% - ${size / 2}px)`,
                left: `calc(50% - ${size / 2}px)`,
                position: 'fixed'
            }}
        />
    ) : (
        <CircularLoading color={color} size={size} {...props} />
    );
};

export default Loading;
