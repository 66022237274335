import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FieldValues } from 'react-hook-form';
import { login } from '../../_old/api/account';
import LoginForm from '../../features/users/organisms/LoginForm/LoginForm';
import { useSnackbar } from 'notistack';
import Header from '../../_old/atomic-design/organisms/Header';
import LoginTemplate from 'templates/UnauthenticatedTemplate';
import { Box, Grid, Paper } from '@mui/material';
import axios from 'axios';
import InactivityListener from 'components/ui/organisms/InactivityListener';

const Login: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    //const location = useLocation();

    async function tryLogin(d: FieldValues) {
        setLoading(true);
        try {
            let response = await login(d.username, d.password);
            if (response) {
                localStorage.setItem('user', d.username);
                enqueueSnackbar('Logged in successfully.', { variant: 'success' });
                window.location.href = '/dashboard';
            }
        } catch (err) {
            enqueueSnackbar(err?.data?.message ?? 'Something went wrong with logging in', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }

    return (
        <LoginTemplate Header={Header}>
            <InactivityListener />
            <Grid container justifyContent={'center'} alignContent={'center'}>
                <Grid item>
                    <Box
                        display={'inline-block'}
                        width={'34rem'}
                        minHeight={'auto'}
                        textAlign={'left'}
                        paddingY={'2rem'}
                        paddingX={'4rem'}
                        boxShadow={3}
                        borderRadius={3}
                        maxWidth='95%'
                        minWidth='30%'>
                        <LoginForm loading={loading} onSubmit={tryLogin} />
                    </Box>
                </Grid>
            </Grid>
        </LoginTemplate>
    );
};

export default Login;
