import * as React from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';



const DateProvider: React.FunctionComponent = ({ children }) => {

    return (<LocalizationProvider dateAdapter={AdapterMoment}>
        {children}
    </LocalizationProvider>)
};

export default DateProvider;
