import * as React from 'react';
import MuiFormLabel, { FormLabelProps } from '@mui/material/FormLabel';

const FormLabel: React.FunctionComponent<FormLabelProps> = (props) => {
    // const classes = useStyles();
    return (
        <MuiFormLabel
            {...props}
            sx={(theme) => ({
                paddingBottom: theme.spacing(1),
                display: 'block',
                fontWeight: 'bold',
                fontSize: 13
            })}
        />
    );
};

export default FormLabel;
