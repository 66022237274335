import React, { useState } from 'react';
import { Grid, Typography, Box, InputAdornment } from '@mui/material';
import TextField from 'components/ui/molecules/TextField';
import { UserIcon } from 'components/ui/atoms/UserIcon';
import { Link } from 'react-router-dom';
import Button from 'components/ui/molecules/Button';
import { FieldValues, useForm } from 'react-hook-form';
import { emailReg } from '../../../_old/helpers/validation';
import { FormContainerProps } from '../../../_old/types/form';
import { emailValidation } from '../utils/validationRules';
import zod from "zod"
import { zodResolver } from '@hookform/resolvers/zod';
import { forgotPasswordSchema } from 'features/users/utils/schemas';

type ForgotPasswordProps = FormContainerProps & {
    onResent: (t: any) => Promise<void>;
};
type ForgotPasswordForm = zod.infer<typeof forgotPasswordSchema>
const ForgotPasswordForm: React.FC<ForgotPasswordProps> = ({ loading, onSubmit, onResent }) => {
    const { handleSubmit, formState: { errors }, control } = useForm<ForgotPasswordForm>({ resolver: zodResolver(forgotPasswordSchema) });
    const [resend, setResend] = useState<boolean>(false);

    function sendVerification(d: FieldValues) {
        setTimeout(() => {
            setResend(true);
        }, 3000);
        if (resend) onResent(d).then((r) => console.log(r));
        else onSubmit(d).then((r) => console.log(r));
    }

    return (
        <form id={'reset-password'} onSubmit={handleSubmit(sendVerification)}>
            <Grid item>
                <Box paddingBottom={1} textAlign="center" marginBottom={'1.5rem'}>
                    <Box display={'inline-block'}>
                        <Typography color="secondary" align="center"  fontSize={'1.75em'} fontWeight={'600'}>
                            Reset your password
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item>
                <Box height={'5.5rem'}>
                    <TextField
                        name={'username'}
                        InputProps={{
                            sx: { height: '3em', marginBottom: '1.5rem'},
                            startAdornment: (
                                <InputAdornment position="start">
                                    <UserIcon size={1.5} />
                                </InputAdornment>
                            )
                        }}
                        control={control}
                        error={Boolean(errors.username)}
                        placeholder={'Username'}
                        autoFocus={true}
                        fullWidth
                        helperText={(errors.username?.type === 'validate' && 'Not a valid email address') || errors.username?.message || ' '}
                    />
                </Box>
            </Grid>
            <Grid item>
                <Box textAlign="center" padding={'1rem'}>
                    <Button sx={{ maxWidth: '90%' }} loading={loading} variant={'contained'} color={'primary'} type={'submit'}>
                        <Box width={'18rem'} sx={{ maxWidth: '90%' }}>
                            <Typography fontSize= '1.5rem' textTransform= 'none'>{resend ? 'Resend' : 'Send'}</Typography>
                        </Box>
                    </Button>
                </Box>
            </Grid>
            <Grid item>
                <Box textAlign="center" padding={'1rem'}>
                    <Button component={Link} to={`/login`} variant={'text'} color={'secondary'}>
                        <Typography textTransform= 'none' variant='body2' fontWeight={'500'}>Back to login</Typography>
                    </Button>
                </Box>
            </Grid>
        </form>
    );
};

export default ForgotPasswordForm;
