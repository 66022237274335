import { Task } from "../../api/types";

export const OBJECTIVE_SET = 'OBJECTIVE_SET';
export const OBJECTIVE_CREATE = 'OBJECTIVE_CREATE';
export const OBJECTIVE_UPDATE = 'OBJECTIVE_UPDATE';
export const OBJECTIVE_LOADING = 'OBJECTIVE_LOADING';


export type NewObjective = Task & {
    meetingId: string
    index?: number
    type: string
}

export type UpdateObjective = Task & {
    meetingId: string
    index?: number
    type: string
}

type NewObjectiveAction = {
    type: typeof OBJECTIVE_CREATE
    payload: NewObjective
}

type UpdateObjectiveAction = {
    type: typeof OBJECTIVE_UPDATE
    payload: UpdateObjective
}


type ObjectiveSetAction = {
    type: typeof OBJECTIVE_SET
    payload: MeetingObjective & {
        type: string
    }
}

type ObjectiveLoadingAction = {
    type: typeof OBJECTIVE_LOADING
    meetingId: string
}

type StateAction = NewObjectiveAction | ObjectiveSetAction | ObjectiveLoadingAction | UpdateObjectiveAction;

export type MeetingObjective = {
    meetingId: string
    objectives: Task[]
    loading: boolean
}

type ObjectiveState = Record<string, MeetingObjective>;

const defaultState: ObjectiveState = {};

const objectiveReducer = (state: ObjectiveState = defaultState, action: StateAction): ObjectiveState => {
    switch (action.type) {
        case OBJECTIVE_CREATE: {
            const { index, meetingId, ...agenda } = action.payload
            if (state[meetingId]) {
                const newState = { ...state[meetingId] };
                if (index && index < newState.objectives.length) {
                    newState.objectives = newState.objectives.splice(index, 0, agenda);
                } else {
                    newState.objectives = [...newState.objectives, agenda];
                }
                return { ...state, [meetingId]: newState };
            }
            return state;
        }

        case OBJECTIVE_UPDATE: {
            const { index, meetingId, _id, ...agenda } = action.payload;
            if (state[meetingId]) {
                const newState = { ...state[meetingId] };
                let objIndex = newState.objectives.findIndex(x => x._id === _id);
                newState.objectives[objIndex] = action.payload;
                return { ...state, [meetingId]: newState };
            }
            return state;
        }
        case OBJECTIVE_SET: {
            return {
                ...state,
                [action.payload.meetingId]: { ...action.payload, loading: false }
            };
        }
        case OBJECTIVE_LOADING: {
            const { meetingId } = action;
            return { ...state, [meetingId]: { loading: true, meetingId, objectives: [] } };
        }

        default:
            return state;
    }
}

export default objectiveReducer;