import io from 'socket.io-client';
import { getUserInfo } from './_old/api/myProfile'
import { NotificationManager } from 'react-notifications';
import store from './store/store';
import { getNotifications } from './store/actions/notification';
import { getObjectives } from './store/actions/objective';
//import { getMeeting } from "./actions/meeting";
import { getTopics } from './store/actions/topic';
import { getSocketInstance } from 'features/meetings/_old/socket/socket';
const socketClient = getSocketInstance();

const updateData = (n) => {
    store.dispatch(getObjectives());
    //store.dispatch(getMeeting());
    store.dispatch(getTopics(n));
};

const insertNotification = async (n) => {
    const user = await getUserInfo();
    const userId = user._id;
    store.dispatch(getNotifications(userId));
    updateData();
};

export const registerNotifications = (history) => {
    // socketClient.on('connect');

    socketClient.on('objective_updated', (payload) => {
        insertNotification(payload);
        NotificationManager.info(payload.message, null, 3000, () => {
            history.push({
                pathname: '/objectives',
                search: '',
                state: { selectedObj: payload.id }
            });
        });
    });

    socketClient.on('objective_reminder', (payload) => {
        insertNotification(payload);
        NotificationManager.info(payload.message, null, 3000, () => {
            history.push({
                pathname: '/objectives',
                search: '',
                state: { selectedObj: payload.id }
            });
        });
    });

    socketClient.on('objective_deleted', (payload) => {
        insertNotification(payload);
        NotificationManager.info(payload.message, null, 3000);
    });

    socketClient.on('meeting_created', (payload) => {
        insertNotification(payload);
        console.log('payload client page', payload);
        NotificationManager.info(payload.message, null, 3000, () => {
            history.push({
                pathname: `/meetings`,
                search: '',
                state: { selectedMtng: payload.id }
            });
        });
    });

    socketClient.on('meeting_reminder', (payload) => {
        insertNotification(payload);
        NotificationManager.info(payload.message, null, 3000, () => {
            history.push({
                pathname: `/meetings`,
                search: '',
                state: { selectedMtng: payload.id }
            });
        });
    });

    // Notificaion Socket Event
    socketClient.on('recieve_notification_change', async (payload) => {
        const user = await getUserInfo();
        const userId = user._id;
        if (payload.user === userId) {
            store.dispatch(getNotifications(userId));
        }
    });

    socketClient.on('meeting_invite_removed', (payload) => {
        insertNotification(payload);
        NotificationManager.info(payload.message);
    });

    socketClient.on('meeting_invite_accepted', (payload) => {
        insertNotification(payload);
        NotificationManager.info(payload.message, null, 3000, () => {
            history.push({
                pathname: `/meetings`,
                search: '',
                state: { selectedMtng: payload.id }
            });
        });
    });

    socketClient.on('update_data', (payload) => {
        updateData(payload.id);
    });
    return () => {
        socketClient.off('objective_updated');
        socketClient.off('objective_reminder');
        socketClient.off('objective_deleted');
        socketClient.off('meeting_created');
        socketClient.off('meeting_reminder');
        socketClient.off('recieve_notification_change');
        socketClient.off('meeting_invite_removed');
        socketClient.off('meeting_invite_accepted');
        socketClient.off('update_data');
    };
};
