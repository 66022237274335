import axios from 'axios';
import { Moment } from 'moment';

const HOST = '/api/v1';

export type Note = {
    _id?: string;
    tags: string[];
    text: string;
    clientID: string;
};

export type ObjectiveStatus = 'Completed' | 'Pending' | 'InProgress';

export type Objective = {
    _id?: string;
    status: ObjectiveStatus;
    tags: string[];
    text: string;
    clientID: string;
    organizationID: string;
    assigneeID: string;
    dueDate: Moment;
    description: string;
    agenda: Record<string, string>;
    ownerID: string;
    history: string[];
};

function url() {
    return HOST;
}

function objectiveUrl(objID?: string | null) {
    return `${url()}/objectives${objID ? `/${objID}` : ''}`;
}



function noteUrl(noteID?: string | null) {
    return `${url()}/notes${noteID ? `/${noteID}` : ''}`;
}



function fetchFolderUrl(orgID: string, userID: string) {
    return `${url()}/notes/folders/${orgID}/user/${userID}`;
}

function createFolderUrl(name: string, orgID: string, userID: string) {
    return `${url()}/notes/folders/${orgID}/user/${userID}/?name=${name}`;
}

export const objective = {
    /**
     *
     * @param {object} objective
     * @returns {Promise<AxiosPromise<any>>}
     */
    create: async (objective: Objective) => {

        return axios.post(objectiveUrl(), { ...objective });
    },

    /**
     * @param {ObjectId} [objID]
     * @returns {Promise<AxiosPromise<any>>}
     */
    get: async (objID?: string) => {
        return axios.get(objectiveUrl(objID));
    },

    /**
     *
     * @param {object} objective
     * @returns {Promise<AxiosPromise<any>>}
     */
    update: async (objective: Objective) => {
        return axios.put<Objective>(objectiveUrl(objective._id), objective);
    },



    /**
     *
     * @param {object} objective
     * @returns {Promise<AxiosPromise>}
     */
    remove: async (objective: Objective) => {
        return axios.delete(objectiveUrl(objective._id));
    }
};

export const note = {
    /**
     *
     * @param {object} note
     * @returns {Promise<AxiosPromise<any>>}
     */
    create: async (note: Note) => {
        return axios.post(noteUrl(), note);
    },

    /**
     * @param {ObjectId} [noteID]
     * @returns {Promise<AxiosPromise<any>>}
     */
    get: async (noteID?: string) => {
        return axios.get(noteUrl(noteID));
    },

    /**
     *
     * @param {object} note
     * @returns {Promise<AxiosPromise<any>>}
     */
    update: async (note: Note) => {
        return axios.put(noteUrl(note._id), note);
    },

    /**
     *
     * @param {object} note
     * @returns {Promise<AxiosPromise>}
     */
    remove: async (note: Note) => {
        return axios.delete(noteUrl(note._id));
    },

    /**
     *
     * @returns {Promise<AxiosPromise<any>>}
     */
    fetchFolders: async (orgID: string, userID: string) => {
        return axios.get(fetchFolderUrl(orgID, userID));
    },

    /**
     *
     * @returns {Promise<AxiosPromise<any>>}
     */
    createFolder: async (name: string, orgID: string, userID: string) => {
        return axios.post(createFolderUrl(name, orgID, userID));
    }
};
