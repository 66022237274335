import React from 'react';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import TextField from 'components/ui/molecules/TextField';
import { UserIcon } from 'components/ui/atoms/UserIcon';


import { zodResolver } from '@hookform/resolvers/zod';
import { Link } from 'react-router-dom';
import Button from 'components/ui/molecules/Button';
import { useForm } from 'react-hook-form';
import { FormProps } from '../../../../_old/types/form';
import zod from "zod"
import { loginFormSchema } from 'features/users/utils/schemas';

type LoginForm = zod.infer<typeof loginFormSchema>

const UserNamePassword: React.FC<FormProps> = ({ formId, loading, onSubmit }) => {
    const { handleSubmit, control, formState: { errors } } = useForm<LoginForm>({ resolver: zodResolver(loginFormSchema) });
    return (
        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
            <Grid item>
                <Box paddingBottom={1} textAlign="center" marginBottom={'1.5rem'}>
                    <Box display={'inline-block'}>
                        <Typography color="secondary" align="center" fontSize='1.75rem' fontWeight='600'>
                            Login to your account
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item>
                <Box height={'4.6rem'}>
                    <TextField
                        name={'username'}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <UserIcon size={1.5} />
                                </InputAdornment>
                            )
                        }}
                        sx={{ marginBottom: '1.5rem' }}
                        control={control}
                        error={Boolean(errors.username)}
                        placeholder={'Username'}
                        autoFocus={true}
                        fullWidth
                        helperText={(errors.username?.type === 'validate' && 'Not a valid email address') || errors.username?.message || ' '}
                    />
                </Box>
            </Grid>
            <Grid item>
                <Box height={'4.6rem'}>
                    <TextField
                        fieldType={'password'}
                        placeholder={'Password'}
                        name={'password'}
                        id={'password'}
                        control={control}

                        error={Boolean(errors.password)}
                        helperText={errors.password?.message || (errors.password?.type && 'Password must be at least 7 characters and must include a number') || ' '}
                        fullWidth
                    />
                </Box>
            </Grid>
            <Grid item>
                <Box textAlign="center" padding={'1rem'}>
                    <Button sx={{ maxWidth: '90%' }} loading={loading} variant={'contained'} color={'primary'} type={'submit'}>
                        <Box width={'18rem'} sx={{ maxWidth: '90%' }}>
                            <Typography fontSize='1.5rem' textTransform='none'>Login</Typography>
                        </Box>
                    </Button>
                </Box>
            </Grid>
            <Grid item>
                <Box textAlign="center" padding={'1rem'}>
                    <Button component={Link} to={`/forgot-password`} variant={'text'} color={'secondary'}>
                        <Typography textTransform= 'none' variant='body2' fontWeight={'500'}>Forgot Password?</Typography>
                    </Button>
                </Box>
            </Grid>
        </form>
    );
};

export default UserNamePassword;
