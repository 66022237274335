import { combineReducers } from 'redux';
import departments from './departments';
import objectives from './objectives';
import notes from './notes';
import users, { User } from './users';
import organizationID from './organizationID';
//import meetings from './meetings';
import topics from './topics';
//import supportingItems from './supportingItems';
import profiles from './profiles';
import globalSearch from './globalSearch';
import notifications from './notifications';
import meetings from 'features/meetings/_old/reducers';
import { emptySplitApi as rtkApi } from '../slices/emptyApi';
import inactivityReducer from '../slices/inactivitySlice';

const rootReducer = combineReducers({
    [rtkApi.reducerPath]: rtkApi.reducer,
    inactivity: inactivityReducer, // Add the inactivity slice
    profiles,
    departments,
    objectives,
    users,
    meetings,
    topics,
    notifications,
    notes,
    organizationID,
    globalSearch
});

export type { User };

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
