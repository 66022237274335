import React, { useState } from 'react';
import ForgotPasswordForm from '../../features/users/organisms/ForgotPasswordForm';
import { FieldValues } from 'react-hook-form';
import { resendRequest } from '../../_old/api/account';
import { resetPassword } from '../../_old/api/authentication';
import { useSnackbar } from 'notistack';
import LoginTemplate from '../../templates/UnauthenticatedTemplate';
import Header from '../../_old/atomic-design/organisms/Header';
import { Box, Grid } from '@mui/material';
import axios from 'axios';

const ForgotPassword: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();

    async function tryReset(d: FieldValues) {
        setLoading(true);
        try {
            await resetPassword(d.username);
            enqueueSnackbar('A verification link has been sent to your email.', { variant: 'success' });
        } catch (err) {
            enqueueSnackbar(err?.data?.message ?? 'Something went wrong while trying to send a verification link', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }

    async function tryResent(d: FieldValues) {
        setLoading(true);
        try {
            await resendRequest(d.username);
            enqueueSnackbar('Resent the verification link', { variant: 'success' });
        } catch (err) {
            enqueueSnackbar(err?.data?.message ?? 'Something went wrong while trying to resend a verification link', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }

    return (
        <LoginTemplate Header={Header}>
            <Grid container justifyContent={'center'} alignContent={'center'}>
                <Grid item>
                    <Box
                        display={'inline-block'}
                        width={'34rem'}
                        minHeight={'auto'}
                        textAlign={'left'}
                        paddingY={'2rem'}
                        paddingX={'4rem'}
                        boxShadow={3}
                        borderRadius="3px"
                        sx={{ backgroundColor: 'white', maxWidth: '95%', minWidth: '30%' }}>
                        <ForgotPasswordForm loading={loading} onSubmit={tryReset} onResent={tryResent} />
                    </Box>
                </Grid>
            </Grid>
        </LoginTemplate>
    );
};

export default ForgotPassword;
