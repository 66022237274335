// export const phoneReg = /^\([1-9]\d{2}\) \d{3}-\d{4}$/;
export const emailReg = /[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-z0-9](?:[A-z0-9-]*[A-z0-9])?\.)+[A-z](?:[A-z]*[A-z])?/;
// export const zipReg = /^[0-9]{5}(?:-[0-9]{4})?$/;
export const mediumRegex = new RegExp('^(?=.*[A-z])(?=.*[0-9])(?=.{7,})');
export const nameRegex = /^[a-z ,.'-]+$/i;
// export const onlyLetters = /^[a-z]+$/i;

// export function formatPhoneNumber(num: string): string {
//     return num.match(/\d+/g)?.join('') ?? ''
// }

// export const stringValidation = (name: string) => ({
//     minLength: { value: 2, message: `Invalid ${name}` },
//     maxLength: {
//         value: 255,
//         message: `Invalid ${name}`
//     }
// })