import { arraymove } from '_old/helpers/utils';
import { AgendaOld } from '../../api/types';
import { CommentType } from 'features/comments/types/comments';

export const AGENDA_REORDER = 'AGENDA_REORDER';
export const AGENDA_UPDATE = 'AGENDA_UPDATE';
export const AGENDA_SET = 'AGENDA_SET';
export const AGENDA_CREATE = 'AGENDA_CREATE';
export const AGENDA_DISCUSSED = 'AGENDA_DISCUSSED';
export const AGENDA_LOADING = 'AGENDA_LOADING';
export const AGENDA_DELETE = 'AGENDA_DELETE';
export const COMMENTS = 'COMMENTS';
export const OBJECTIVE_CREATE = 'OBJECTIVE_CREATE';
export const COMMENTS_DELETE = 'COMMENTS_DELETE';
export const AGENDA_OBJECTIVE_CREATE = 'AGENDA_OBJECTIVE_CREATE';
export const AGENDA_OBJECTIVE_COMMENT_ADD = 'AGENDA_OBJECTIVE_COMMENT_ADD';

export type Reorder = {
    meetingId: string;
    agendaId: string;
    to: number;
};

export type Delete = {
    meetingId: string;
    agendaId: string;
};

export type NewAgenda = AgendaOld & {
    meetingId: string;
    index?: number;
};

type ReorderAction = {
    type: typeof AGENDA_REORDER;
    payload: Reorder;
};

type AgendaUpdateAction = {
    type: typeof AGENDA_UPDATE;
    payload: NewAgenda;
};

type NewAgendaAction = {
    type: typeof AGENDA_CREATE;
    payload: NewAgenda;
    index: number | null;
};

type AgendaSetAction = {
    type: typeof AGENDA_SET;
    payload: MeetingAgenda;
};

type AgendaLoadingAction = {
    type: typeof AGENDA_LOADING;
    meetingId: string;
};

type AgendaDeleteAction = {
    type: typeof AGENDA_DELETE;
    meetingId: string;
    agendaId: string;
    agenda: AgendaOld;
    payload: Delete;
};

type CommentsAction = {
    type: typeof COMMENTS
    payload: { agendaId: string, meetingId: string, comment: string, _id: string, ownerID: string, createdOn: Date, parentId?: string }
}


type ObjectiveAction = {
    type: typeof OBJECTIVE_CREATE;
    meetingId: any;
    agendaId: any;
    agenda: any;
    agendaId1: any;
    ownerId: any;
    commentText: any;
    payload: NewAgenda;
    comments: any;
    description: any;
    assignedBy: any;
    assigneeID: any;
};

type CommentsDeleteAction = {
    type: typeof COMMENTS_DELETE
    payload: { agendaId: string, meetingId: string, commentId: string, parentId?: string }
}

type AddObjectiveAction = {
    type: typeof AGENDA_OBJECTIVE_CREATE;
    payload: {
        meetingId: string;
        agendaId: string;
        objective: any;
    };
};
export type ObjectivesCommentsAction = {
    type: typeof AGENDA_OBJECTIVE_COMMENT_ADD
    payload: { agendaId: string, meetingId: string, comments: CommentType[], objectiveId: string }
}

type StateAction = ReorderAction | NewAgendaAction | AgendaUpdateAction | AgendaSetAction | AgendaLoadingAction | AgendaDeleteAction | CommentsAction | ObjectiveAction | CommentsDeleteAction | AddObjectiveAction | ObjectivesCommentsAction;


export type MeetingAgenda = {
    meetingId: string;
    agendas: AgendaOld[];
    loading: boolean;
    receivedAt?: number;
    objectiveID?: [];
};

type AgendaState = Record<string, MeetingAgenda>;

const defaultState: AgendaState = {};

const agendaReducer = (state: AgendaState = defaultState, action: StateAction): AgendaState => {

    switch (action.type) {
        case AGENDA_REORDER:
        case AGENDA_CREATE:
        case AGENDA_UPDATE:
        case AGENDA_DELETE:
        case AGENDA_OBJECTIVE_COMMENT_ADD:
        case COMMENTS:
        case OBJECTIVE_CREATE:
        case COMMENTS_DELETE:
        case AGENDA_OBJECTIVE_CREATE:
            // Ensure the meeting state exists and agendas is initialized for any action that requires it
            const { meetingId } = action.payload;
            if (!state[meetingId]) {
                state = {
                    ...state,
                    [meetingId]: {
                        agendas: []
                    }
                };
            }
            break;
        default:
            break;
    }
    //console.log("Types = "+action.type)
    switch (action.type) {
        case AGENDA_REORDER: {
            const { meetingId, agendaId, to } = action.payload;
            const meeting = state[meetingId];
            if (!meeting) {
                console.error('Meeting not found:', meetingId);
                return state; // Exit early if meeting does not exist
            }
        
            const agendas = meeting.agendas;
            const agendaIndex = agendas.findIndex(a => a?._id === agendaId);
            if (agendaIndex === -1) {
                console.error('Agenda not found:', agendaId);
                return state; // Exit early if the agenda does not exist
            }
        
            // Perform the reorder operation safely
            const newState = {...meeting};
            newState.agendas = arraymove(agendas, agendaIndex, to);
            return {...state, [meetingId]: newState};
        }
        case AGENDA_CREATE: {
            const { meetingId, ...agenda } = action.payload;
            const index = action.index;
            console.log(index, agenda);
            if (state[meetingId]) {
                const newState = { ...state[meetingId] };
                if (index && index < newState.agendas.length) {
                    newState.agendas.splice(index, 0, agenda);
                } else {
                    newState.agendas = [...newState.agendas, agenda];
                }
                return { ...state, [meetingId]: newState };
            }
            return state;
        }
        case AGENDA_UPDATE: {
            const { meetingId, _id, ...agenda } = action.payload;

            const meeting = state[meetingId];
            if (!meeting) {
                // Handle the case where the meeting doesn't exist in the state
                return state;
            }

            const agendas = meeting.agendas || [];
            const agendaIndex = agendas.findIndex((a) => a._id === _id);
            if (agendaIndex === -1) {
                // Handle the case where the agenda doesn't exist in the state
                return state;
            }

            // Create a new copy of the agenda array
            const updatedAgendas = [...agendas];

            // Update the specific agenda
            updatedAgendas[agendaIndex] = { _id, ...agenda };

            // Create a new meeting object with the updated agendas
            const updatedMeeting = {
                ...meeting,
                agendas: updatedAgendas,
            };

            return {
                ...state,
                [meetingId]: updatedMeeting,
            };
        }
        case AGENDA_SET: {
            return {
                ...state,
                [action.payload.meetingId]: { ...action.payload, loading: false, receivedAt: Date.now() }
            };
        }
        case AGENDA_LOADING: {
            const { meetingId } = action;
            return { ...state, [meetingId]: { loading: true, meetingId, agendas: [] } };
        }

        case AGENDA_DELETE: {
            const { meetingId, agendaId } = action.payload;
            const meeting = state[meetingId];
            if (meeting) {
                // Filter out the deleted agenda
                const updatedAgendas = meeting.agendas.filter(agenda => agenda._id !== agendaId);
                // Update the meeting state with the new agendas array
                return { 
                    ...state, 
                    [meetingId]: {
                        ...meeting,
                        agendas: updatedAgendas
                    }
                };
            }
            return state;
        }
        case AGENDA_OBJECTIVE_COMMENT_ADD: {

            const { meetingId, agendaId, comments, objectiveId } = action.payload;
            const meeting = state[meetingId];
            if (!meeting) {
                return state;
            }

            const agendas = meeting.agendas || [];
            const agendaIndex = agendas.findIndex((a) => a._id === agendaId);
            if (agendaIndex === -1) {
                return state;
            }

            const agenda = agendas[agendaIndex];
            const objectives = agenda.objectives;
            const objectiveIndex = objectives.findIndex((a) => a._id === objectiveId);
            if (objectiveIndex === -1) {
                return state;
            }
            const objective = objectives[objectiveIndex]
            const updatedObjective = {
                ...objective,
                comments: comments
            }
            const updatedObjectives = [...objectives];
            updatedObjectives[objectiveIndex] = updatedObjective
            const updatedAgenda = {
                ...agenda,
                objectives: updatedObjectives,
            };

            const updatedAgendas = [...agendas];
            updatedAgendas[agendaIndex] = updatedAgenda;

            const updatedMeeting = {
                ...meeting,
                agendas: updatedAgendas,
            };

            return {
                ...state,
                [meetingId]: updatedMeeting,
            };

        }
        case COMMENTS: {
            const { meetingId, agendaId, parentId, _id, ownerID, createdOn, comment } = action.payload;
            const meeting = state[meetingId];
            if (!meeting) {
                return state;
            }

            const agendas = meeting.agendas || [];
            const agendaIndex = agendas.findIndex((a) => a._id === agendaId);
            if (agendaIndex === -1) {
                return state;
            }

            const agenda = agendas[agendaIndex];
            const existingCommentIndex = agenda.comments.findIndex((c) => c._id === _id);

            let updatedComments;
            if (existingCommentIndex === -1) {
                // Add new comment
                updatedComments = [...(agenda.comments || []), { doc: comment, _id, ownerID, parentId: parentId ?? null, createdOn: createdOn.toString() }];
            } else {
                // Update existing comment
                updatedComments = [...agenda.comments];
                updatedComments[existingCommentIndex] = { doc: comment, _id, ownerID, parentId: parentId ?? null, createdOn: createdOn.toString() };
            }

            const updatedAgenda = {
                ...agenda,
                comments: updatedComments,
            };

            const updatedAgendas = [...agendas];
            updatedAgendas[agendaIndex] = updatedAgenda;

            const updatedMeeting = {
                ...meeting,
                agendas: updatedAgendas,
            };

            return {
                ...state,
                [meetingId]: updatedMeeting,
            };
        }

        case OBJECTIVE_CREATE: {
            const { meetingId, ...agenda } = action.payload;
            const newState = { ...state[meetingId] };
            const { agendaId } = action;

            const objs = {
                obj_Id: agenda._id,
                ownerID: agenda.assignedBy,
                asigneeID: agenda.assigneeID,
                desc: agenda.description
            };
            let agIndex = newState.agendas.findIndex((x) => x._id === agendaId);
            newState.agendas[agIndex].objectiveID.push(objs);
            return { ...state, [meetingId]: newState };
        }

        case COMMENTS_DELETE: {
            const { meetingId, agendaId, commentId, parentId, } = action.payload;
            const meeting = state[meetingId];
            if (!meeting) {
                // Handle the case where the meeting doesn't exist in the statea
                return state;
            }

            const agendas = meeting.agendas || [];
            const agendaIndex = agendas.findIndex((a) => a._id === agendaId);
            if (agendaIndex === -1) {
                // Handle the case where the agenda doesn't exist in the state
                return state;
            }

            const agenda = agendas[agendaIndex];
            const updatedAgenda = {
                ...agenda,
                comments: agenda.comments.filter((comment) => comment._id !== commentId),
            };

            const updatedAgendas = [...agendas];
            updatedAgendas[agendaIndex] = updatedAgenda;

            const updatedMeeting = {
                ...meeting,
                agendas: updatedAgendas,
            };
            return {
                ...state,
                [meetingId]: updatedMeeting,
            };
        }
        case AGENDA_OBJECTIVE_CREATE: {
            const { meetingId, agendaId, objective } = action.payload;
            const meeting = state[meetingId];
            if (!meeting) {
                return state;
            }

            const agendas = meeting.agendas || [];
            const agendaIndex = agendas.findIndex((a) => a._id === agendaId);
            if (agendaIndex === -1) {
                return state;
            }

            const agenda = agendas[agendaIndex];

            let updatedObjectives;
            // Add new comment
            updatedObjectives = [...(agenda.objectives || []), { ...objective }];

            const updatedAgenda = {
                ...agenda,
                objectives: updatedObjectives,
            };

            const updatedAgendas = [...agendas];
            updatedAgendas[agendaIndex] = updatedAgenda;

            const updatedMeeting = {
                ...meeting,
                agendas: updatedAgendas,
            };

            return {
                ...state,
                [meetingId]: updatedMeeting,
            };
        }


        default:
            return state;
    }
};

export default agendaReducer;
