import axios from 'axios';
import { notification as api } from '_old/helpers/api';

import { Notification } from '_old/helpers/api/notifications';
import { getSocketInstance } from 'features/meetings/_old/socket/socket';

const socketNotificationUpdate = (userId: any) => {
    getSocketInstance().emit('notification_change', userId);
};

export const receiveNotifications = (notifications: Notification[]) => {
    return {
        type: 'RECEIVE_NOTIFICATIONS',
        payload: notifications
    };
};

export const receiveNotification = (notification: any) => {
    return {
        type: 'RECEIVE_NOTIFICATION',
        payload: notification
    };
};

export const removeNotification = (notification: any) => {
    return {
        type: 'REMOVE_NOTIFICATION',
        payload: notification
    };
};

export const markReadNotification = (notification: any) => {
    return {
        type: 'READ_NOTIFICATION',
        payload: notification
    };
};

export const getNotifications = (userId: any) => {
    return async (dispatch: any) => {
        try {
            const { data } = await api.get(userId);

            dispatch(receiveNotifications(data));
        } catch (err) {
            if (axios.isAxiosError(err)) {
                throw err.message;
            } else {
                console.error(err);
            }
        }
    };
};

// export const addNotification = (notification:any) => {
//   return async (dispatch:any) => {
//     try {
//       // Trigger Socket Event
//       const userId = JSON.parse(localStorage.profiles)[0]._id;
//       socketNotificaitonUpdate(userId);

//       dispatch(receiveNotification(notification));
//       return notification;
//     } catch (err) {
//       //console.error(err.data.message);
//       throw err.data.message;
//     }
//   };
// };

export const readNotification = (notification: any, userId: any) => {
    return async (dispatch: any) => {
        try {
            let o = await api.patch(notification._id);
            o = o.data;

            // Trigger Socket Event
            socketNotificationUpdate(userId);

            dispatch(markReadNotification(notification));
            return notification;
        } catch (err) {
            //console.error(err.data.message);
            throw err.data.message;
        }
    };
};

export const deleteNotification = (notification: any, userId: any) => {
    return async (dispatch: any) => {
        try {
            let o = await api.delete(notification._id);
            o = o.data;

            // Trigger Socket Event
            socketNotificationUpdate(userId);

            dispatch(removeNotification(notification));
            return notification;
        } catch (err) {
            //console.error(err.data.message);
            throw err.data.message;
        }
    };
};
