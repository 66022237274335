import { CacheProvider, ThemeProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import CssBaseline from '@mui/material/CssBaseline';
import { FC } from 'react';
import { theme } from '../theme';
const cache = createCache({
    key: 'css',
    prepend: true,
});
const MuiThemeProvider: FC = ({ children }) => (
    <CacheProvider value={cache}>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    </CacheProvider>
);
export default MuiThemeProvider;
