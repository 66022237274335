import { createSlice } from '@reduxjs/toolkit';

const inactivitySlice = createSlice({
    name: 'inactivity',
    initialState: {
        isAlertOpen: false
    },
    reducers: {
        showSignedOutAlert: (state) => {
            state.isAlertOpen = true;
        },
        hideSignedOutAlert: (state) => {
            state.isAlertOpen = false;
        }
    }
});

export const { showSignedOutAlert, hideSignedOutAlert } = inactivitySlice.actions;
export default inactivitySlice.reducer;
