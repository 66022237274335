import React, { useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { changePassword } from '../../_old/api/authentication';
import ChangePasswordForm from '../../features/users/organisms/ChangePasswordForm';
import { useNavigate, useParams} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Header from '../../_old/atomic-design/organisms/Header';
import LoginTemplate from '../../templates/UnauthenticatedTemplate';
import { Box, Grid, Paper } from '@mui/material';
import axios from 'axios';

const ChangePassword: React.FC = () => {
    let { token } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    async function tryChangePassword(d: FieldValues) {
        setLoading(true);
        try {
            let body = {
                password: d.password,
                confirmPassword: d.confirm
            };
            await changePassword(token, body);

            enqueueSnackbar('Password changed successfully.', { variant: 'success' });
            navigate('/login');
        } catch (err) {
            enqueueSnackbar(err?.data?.message ?? 'Something went wrong while trying to change your password', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }
    return (
        <LoginTemplate Header={Header}>
            <Grid container justifyContent={'center'} alignContent={'center'}>
                <Grid item>
                    <Box
                        display={'inline-block'}
                        width={'34rem'}
                        minHeight={'auto'}
                        textAlign={'left'}
                        paddingY={'2rem'}
                        paddingX={'4rem'}
                        boxShadow={3}
                        borderRadius={3}
                        sx={{ backgroundColor: 'white', maxWidth: '95%', minWidth: '30%' }}>
                        <ChangePasswordForm loading={loading} onSubmit={tryChangePassword} />
                    </Box>
                </Grid>
            </Grid>
        </LoginTemplate>
    );
};

export default ChangePassword;
