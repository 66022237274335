import { Meeting, Task } from "../../api/types";

export const MEETING_SET = 'MEETING_SET';
export const MEETING_CREATE = 'MEETING_CREATE';
export const MEETING_LOADING = 'MEETING_LOADING';
export const MEETING_MERGE = 'MEETING_MERGE';
export const MEETING_DELETE = 'MEETING_DELETE';

export type NewMeeting = Meeting & {
    meetingId: string
    type: string
}

type NewMeetingAction = {
    type: typeof MEETING_CREATE
    payload: NewMeeting
}

type MeetingSetAction = {
    type: typeof MEETING_SET
    payload: Meeting
}

type MeetingLoadingAction = {
    type: typeof MEETING_LOADING
    meetingId: string
}

type MeetingMergeAction = {
    type: typeof MEETING_MERGE,
    meetingId: string,
    payload: Partial<Meeting>
}
type MeetingDeleteAction = {
    type: typeof MEETING_DELETE,
    payload: string,
}

type StateAction = NewMeetingAction | MeetingSetAction | MeetingLoadingAction | MeetingMergeAction | MeetingDeleteAction;

export type MeetingObj = {
    meeting: Meeting | null
    loading: boolean
    receivedOn?: number
}

type MeetingState = Record<string, MeetingObj>;

const defaultState: MeetingState = {};

const meetingReducer = (state: MeetingState = defaultState, action: StateAction): MeetingState => {
    switch (action.type) {
        case MEETING_CREATE: {
            const { meetingId, ...meeting } = action.payload
            if (state[meetingId]) {
                const newState = { ...state[meetingId] };
                newState.meeting = meeting;
                return { ...state, [meetingId]: newState };
            }
            return state;
        }
        case MEETING_SET: {
            return {
                ...state,
                [action.payload._id]: { meeting: action.payload, loading: false, receivedOn: Date.now() }
            };
        }
        case MEETING_LOADING: {
            const { meetingId } = action;
            return { ...state, [meetingId]: { loading: true, meeting: null } };
        }
        case MEETING_MERGE: {
            const { meetingId, payload } = action;
            const newState = { ...state, [meetingId]: { ...state[meetingId], meeting: { ...state[meetingId].meeting, ...payload } as Meeting } };
            return newState;
        }
        case MEETING_DELETE: {
            const meetingId = action.payload;
            const newState = { ...state };
            delete newState[meetingId];
            return newState;
        }
        default:
            return state;
    }
}

export default meetingReducer;