import React, { FC, useState, forwardRef  } from 'react';
import { TextFieldProps as MuiProps } from '@mui/material/TextField';
import { Control } from 'react-hook-form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { FormHelperText, InputAdornment } from '@mui/material';
import { Controller } from 'react-hook-form';
import Input from '../../atoms/Input';
import FormLabel from '../../atoms/form-controls/FormLabel';
import FormControl from '../../atoms/form-controls/FormControl';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff, VpnKey } from '@mui/icons-material';
import { PatternFormat } from 'react-number-format';

export type ControllerProps = {
    control?: Control<any>;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    name?: string;
};

export type TextFieldProps = ControllerProps &
    MuiProps & {
        fieldType?: 'password' | 'text' | 'phone';
    };

const BaseTextField: FC<TextFieldProps> = ({
    autoComplete,
    autoFocus = false,
    children,
    classes,
    className,
    color = 'primary',
    defaultValue,
    disabled = false,
    error = false,
    FormHelperTextProps,
    fullWidth = false,
    helperText,
    hiddenLabel,
    id,
    InputLabelProps,
    inputProps,
    InputProps,
    inputRef,
    label,
    maxRows,
    multiline,
    name,
    onPaste,
    onMouseDown,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    required = false,
    rows,
    select = false,
    SelectProps,
    type,
    value,
    fieldType = 'text',
    variant = 'standard',
    ...other
}) => {
    const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
    const inputLabelId = label && id ? `${id}-label` : undefined;
    const [show, setShow] = useState(false);
    const allInputProps =
        fieldType === 'password'
            ? {
                startAdornment: (
                    <InputAdornment position="start">
                        <VpnKey sx={{ color: '#FF7315' }} />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton aria-label={'Toggle password visibility'} onClick={() => setShow(!show)} size="large">
                            {show ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
                type: show ? 'text' : 'password'
            }
            : fieldType === 'phone'
            ? {
                inputComponent: PatternFormat as any,
                inputProps: {
                    format: "(###) ###-####",
                    mask: "_",
                },
            }
            : { ...InputProps };

    return (
        <FormControl disabled={disabled} error={error} fullWidth={fullWidth} hiddenLabel={hiddenLabel} required={required} color={color} variant={variant} {...other}>
            {label && (
                <FormLabel htmlFor={id} id={inputLabelId}>
                    {label}
                </FormLabel>
            )}
            <Input
                name={name}
                id={id}
                type={type}
                placeholder={placeholder}
                aria-describedby={helperTextId}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                maxRows={maxRows}
                rows={rows}
                defaultValue={defaultValue}
                inputRef={inputRef}
                inputProps={inputProps}
                onPaste={onPaste}
                onMouseDown={onMouseDown}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                multiline={multiline}
                value={value}
                {...allInputProps}
            />
            {helperText && (
                <FormHelperText sx={{ display: 'flex', justifyContent: 'right' }} id={helperTextId} {...FormHelperTextProps}>
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
};

const TextField: FC<TextFieldProps> = ({ control, rules, name, error, helperText, defaultValue, disabled, ...props }) => {
    if (control && name) {
        return (
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue ? defaultValue : ''}
                rules={rules}
                render={({ field }) => <BaseTextField helperText={helperText} error={error} {...props} {...field} disabled={disabled ?? false} />}
            />
        );
    } else {
        return <BaseTextField name={name} defaultValue={defaultValue} helperText={helperText} error={error} {...props} />;
    }
};

export default TextField;
