import * as React from 'react';
import MuiFormControl, { FormControlProps } from '@mui/material/FormControl';

interface IFormControlProps extends FormControlProps {}

const FormControl: React.FunctionComponent<IFormControlProps> = ({ variant, children, ...props }) => {
    return (
        <MuiFormControl variant="outlined" {...props}>
            {children}
        </MuiFormControl>
    );
};

export default FormControl;
