import axios, { AxiosPromise } from 'axios';

const HOST = '/api/v1';

function url() {
    return HOST;
}

function notificationUrl(userId: string) {
    return `${url()}/notifications/user/${userId}`;
}

function notificationReadUrl(notificationId: string) {
    return `${url()}/notifications/${notificationId}/read`;
}

function notificationDeleteUrl(notificationId: string) {
    return `${url()}/notifications/${notificationId}`;
}
export interface Notification {
    _id: string;
    payload: Record<'title' | 'message' | 'id', string>;
    recipientId: string;
    read: boolean;
    createDate: string;
    __v: number;
    trash: boolean;
}
export const notification = {
    /**
     * @param {ObjectId} [objID]
     * @returns {Promise<AxiosPromise<Notification[]>>}
     */
    get: async (userId: string): Promise<AxiosPromise<Notification[]>> => {
        return axios.get<Notification[]>(notificationUrl(userId));
    },
    patch: async (notificationId: string) => {
        return axios.patch(notificationReadUrl(notificationId));
    },
    delete: async (notificationId: string) => {
        return axios.delete(notificationDeleteUrl(notificationId));
    }
};
