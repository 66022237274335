import React from 'react';
import { Account } from '_old/types/account';
import { Box, Grid, Typography } from '@mui/material';
import TextField from 'components/ui/molecules/TextField';
import Button from 'components/ui/molecules/Button';
import { Link } from 'react-router-dom';
import { FormProps } from '_old/types/form';
import { useForm } from 'react-hook-form';
import { nameRegex, emailReg, mediumRegex } from '_old/helpers/validation';
import zod from "zod"
import { zodResolver } from '@hookform/resolvers/zod';
import { signUpSchema } from 'features/users/utils/schemas';

const SignUp: React.FC<FormProps> = ({ formId, loading, onSubmit }) => {
    const { handleSubmit, formState: { errors }, getValues, control } = useForm<Account>({
        resolver: zodResolver(signUpSchema),
    });
    return (
        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
            <Grid item>
                <Box paddingBottom={1} textAlign="center" marginBottom={'1rem'}>
                    <Box display={'inline-block'}>
                        <Typography color="secondary" align="center" fontSize='1.75rem' fontWeight='600'>
                            Create your account
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item>
                <Box height={'4.6rem'}>
                    <TextField
                        name={'firstName'}
                        id="first-name"
                        control={control}
                        InputProps={{ style: { height: '3rem' } }}
                        //rules={{ required: 'First name required', minLength: 2, pattern: nameRegex }}
                        error={Boolean(errors.firstName)}
                        placeholder={'First Name'}
                        autoFocus={true}
                        fullWidth
                        helperText={errors.firstName?.message || ' '}
                    />
                </Box>
            </Grid>
            <Grid item>
                <Box height={'4.6rem'}>
                    <TextField
                        name={'lastName'}
                        id="last-name"
                        control={control}
                        InputProps={{ style: { height: '3rem' } }}
                        //rules={{ required: 'Last name required', minLength: 2, pattern: nameRegex }}
                        error={Boolean(errors.lastName)}
                        placeholder={'Last Name'}
                        autoFocus={true}
                        fullWidth
                        helperText={errors.lastName?.message || ' '}
                    />
                </Box>
            </Grid>
            <Grid item>
                <Box height={'4.6rem'}>
                    <TextField
                        name={'username'}
                        id="username"
                        control={control}
                        InputProps={{ style: { height: '3rem' } }}
                        //rules={{ required: 'Email required', validate: (v) => emailReg.test(v) }}
                        error={Boolean(errors.username)}
                        placeholder={'Email'}
                        autoFocus={true}
                        fullWidth
                        helperText={(errors.username?.type === 'validate' && 'Not a valid email address') || errors.username?.message || ' '}
                    />
                </Box>
            </Grid>
            <Grid item>
                <Box height={'5rem'}>
                    <TextField
                        fieldType={'password'}
                        id="password"
                        placeholder={'Create Password'}
                        InputProps={{ style: { height: '3rem' } }}
                        name={'password'}
                        control={control}
                        //rules={{required: 'Password required', minLength: 7, validate: (v) => mediumRegex.test(v) }}
                        error={Boolean(errors.password)}
                        helperText={errors.password?.message || (errors.password?.type && 'Password must be at least 7 characters and must include a number') || ' '}
                        fullWidth
                    />
                </Box>
            </Grid>
            <Grid item>
                <Box height={'4.6rem'}>
                    <TextField
                        fieldType={'password'}
                        placeholder={'Confirm Password'}
                        name={'confirmPassword'}
                        id={'confirmPassword'}
                        control={control}
                        InputProps={{ style: { height: '3rem' } }}
                        rules={{
                            required: 'Confirm Password required',
                            minLength: 7,
                            validate: (v) => v === getValues().password
                        }}
                        error={Boolean(errors.confirmPassword)}
                        helperText={errors.confirmPassword?.message || (errors.confirmPassword?.type && 'Password does not match') || ' '}
                        fullWidth
                    />
                </Box>
            </Grid>
            <Grid item>
                <Box textAlign="center" padding={'1rem'}>
                    <Button sx={{ maxWidth: '90%' }} loading={loading} variant={'contained'} color={'primary'} type={'submit'}>
                        <Box width={'18rem'} maxWidth= '90%'>
                            <Typography fontSize='1.5rem' textTransform='none'>Submit</Typography>
                        </Box>
                    </Button>
                </Box>
            </Grid>
            <Grid item>
                <Box textAlign="center" padding={'1rem'}>
                    <Button component={Link} to={`/login`} variant={'text'} color={'secondary'}>
                    <Typography variant='body2' textTransform='none' fontWeight={'500'}>Back to login</Typography>
                    </Button>
                </Box>
            </Grid>
        </form>
    );
};

export default SignUp;
