import React from 'react';

import { FormContainerProps } from '../../../../_old/types/form';
import UserNamePassword from './UserNamePasswordForm';

const LoginForm: React.FC<FormContainerProps> = ({ loading, onSubmit }) => {
    return (
        <UserNamePassword formId={'login'} loading={loading} onSubmit={onSubmit} />
    );
};

export default LoginForm;
