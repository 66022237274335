import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Box, Button } from '@mui/material';
import logo from '/logo.png';
import { Link } from 'react-router-dom';

const Header: React.FC<{ isAuthed?: boolean }> = ({ isAuthed }) => {
    // const { classes } = useHeaderStyles();
    return (

        <AppBar position="sticky" sx={{
            // position: 'fixed',
            // marginLeft: drawerWidth,
            backgroundColor: '#fff'
        }} >
            <Toolbar disableGutters >
                {/* <Box flexGrow={1} ml={1}>
                    <Link to={'/login'}>
                        <Box sx={{ display: { xs: 'none', md: 'flex', maxHeight: 70 } }} component="img" alt="Desktop Logo" src={logo} />
                        <Box sx={{ display: { xs: 'flex', md: 'none' }, maxHeight: 70 }} component="img" alt="mobile Logo" src={mobileLogo} />
                    </Link>
                </Box> */}
                <Box marginRight={2} marginLeft={3}>
                    <Link className="logo_big" to="/dashboard">
                        <img src={logo} alt="logo" width={'250'} />
                    </Link>
                </Box>

                <Button variant="contained" sx={{ borderRadius: 0, justifySelf: 'stretch', alignSelf: 'stretch', marginLeft: 'auto' }} component={Link} to={'/create-account'} color="primary">
                    Sign up
                </Button>

                {/* todo later */}
                {/* {isAuthed ? (
                    
                    <Button variant="contained" sx={{ borderRadius: 0, justifySelf: 'stretch', alignSelf: 'stretch' }} component={Link} to={'/create-account'} color="primary">
                        Sign up
                    </Button>
                ) : (
                    <Button variant="contained" sx={{ borderRadius: 0, justifySelf: 'stretch', alignSelf: 'stretch' }} component={Link} to={'/create-account'} color="primary">
                        Sign up
                    </Button>
                )} */}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
