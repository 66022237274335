import axios from 'axios';
import { getLocalToken } from '../../../helpers/api';
import { MtngCardData, MtngGraph, User } from '../../../features/MyProfile/types';

const BASE = '/api/v1/account/users';
const AVATARHOST = '/api/v1/account';
// const token = getLocalToken()
export type UpdateUserAccountType = Record<'firstName' | 'lastName' | 'address', string | any>;
async function updateUserAccount(userID: string, body: Partial<UpdateUserAccountType>): Promise<string> {
    try {
        const { data } = await axios.put(`${BASE}/${userID}`, { modUser: body });
        // updated user
        console.log(data);
        updateUserLocalInfo(data.user);
        return data;
    } catch (error) {
        return error.data.message;
    }
}
async function getMeetingData(): Promise<MtngCardData> {
    try {
        const { data } = await axios.get(`${BASE}/meeting-data`);
        return data;
    } catch (error) {
        return error.data.message;
    }
}

async function uploadAvatarImage(userID: string, avatar: FormData): Promise<string> {
    try {
        const { data } = await axios.post(`${AVATARHOST}/users/${userID}/avatar/`, avatar);
        return data;
    } catch (error) {
        return error.data.message;
    }
}

async function profileGraph(): Promise<MtngGraph[]> {
    try {
        const { data } = await axios.get(`${BASE}/profile-graph`);
        return data;
    } catch (error) {
        return error.data.message;
    }
}

async function getUserInfo() {
    try {
        const { data } = await axios.get("/api/v1/account/users/current");
        const user: User = data;
        return user
    } catch (e) {
        return null;
    }
}

function updateUserLocalInfo(data: User) {
    window.localStorage.setItem('profiles', JSON.stringify([data]));
}

export { updateUserAccount, getUserInfo, uploadAvatarImage, getMeetingData, profileGraph };
