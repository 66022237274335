import axios from 'axios';
import Storage from '../helpers/storage';
import { Account, Credentials, LogoutResponseData, Organization } from '../types/account';
import { BaseAddress } from './index';

/**
 * @method login
 * @param username
 * @param password
 * @returns {Promise<{token, refreshToken}>}
 */
export async function login(username: string, password: string): Promise<Credentials> {
    return axios.post('/api/v1/authentication/login', { username, password }).then((response) => {
        const { token, refreshToken } = response.data;
        Storage.token.access = token;
        Storage.token.refresh = refreshToken;
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        return { token, refreshToken };
    });
}

/**
 * @method resendRequest
 * @param username
 * @returns {Promise<{string}>}
 */
export async function resendRequest(username: string): Promise<'success' | 'failed to send'> {
    const res = await axios.get(`/api/v1/account/verification?username=${username}`);
    return res.data;
}
// not used
// /**
//  * @method token
//  * @returns {Promise<{token, refreshToken}>}
//  */
// export async function token(): Promise<Credentials> {
//     const d = await axios.post('/api/v1/authentication/token', {
//         userID: Storage.token.clientID,
//         refreshToken: Storage.token.refresh
//     });
//     return d.data;
// }

/**
 * @method createAccount
 * @param client
 * @returns {Promise<any>}
 */
export async function createAccount(client: Account): Promise<any> {
    let d = await axios.post('/api/v1/account/register', { data: client });
    return d.data;
}

/**
 * @method logout
 * @returns {Promise<string>}
 */
export async function logout(): Promise<string> {
    try {
        const { data } = await axios.delete<LogoutResponseData>('/api/v1/authentication/logout');
        // Clear local storage or any other client-side data
        Storage.logout();
        return data.redirect;
    } catch (error) {
        throw error;
    }
}
