import { Popper, Theme, createTheme, responsiveFontSizes } from '@mui/material';

export const themeObject = {
    palette: {
        primary: {
            light: '#ffad33',
            main: '#FF7315',
            dark: '#b26b00',
            contrastText: '#fff'
        },
        secondary: {
            main: '#807AA5',
            contrastText: '#fff'
        }
    },
    typography: {
        h3: {
            display: 'block',
            fontSize: 14,
            marginBottom: '1em',
            marginLeft: 0,
            marginRight: 0,
            fontWeight: 'bold'
        }
    }
};
const CustomPopper = (props) => {
    return <Popper {...props} placement="bottom" sx={{ height: '15px', width: props.anchorEl.clientWidth }} />;
};
const muiTheme: Theme = createTheme({
    ...themeObject,
    components: {
        MuiButton: { defaultProps: { size: 'medium' } },
        MuiAutocomplete: { defaultProps: { PopperComponent: CustomPopper } },
        MuiInputBase: { defaultProps: { size: 'small' } },
        MuiDialog: { defaultProps: { fullWidth: true } },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: 'h1',
                    h2: 'h2',
                    h3: 'h3',
                    h4: 'h4',
                    h5: 'h5',
                    h6: 'h6'
                }
            }
        }
    }
});

export const theme = responsiveFontSizes(muiTheme);
