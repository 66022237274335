import React, { useState } from 'react';
import LoginTemplate from '../../templates/UnauthenticatedTemplate';
import Header from '../../_old/atomic-design/organisms/Header';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { createAccount } from '../../_old/api/account';
import { Account } from '_old/types/account';
import { Grid, Box } from '@mui/material';
import SignUp from 'features/users/organisms/SignUpForm/SignUp';

const CreateAccount: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    async function tryAccountCreation(d: Account) {
        setLoading(true);
        try {
            let response = await createAccount(d);
            if (response) {
                enqueueSnackbar('Created account successfully.', { variant: 'success' });
                navigate({ ...location, pathname: '/login' });
            }
        } catch (err) {
            enqueueSnackbar(err?.data?.message ?? 'Something went wrong while trying to create your account', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }

    return (
        <LoginTemplate Header={Header}>
            <Grid container justifyContent={'center'} alignContent={'center'}>
                <Grid item>
                    <Box
                        display={'inline-block'}
                        width={'34rem'}
                        minHeight={'auto'}
                        textAlign={'left'}
                        paddingY={'2rem'}
                        paddingX={'4rem'}
                        boxShadow={3}
                        borderRadius={3}
                        sx={{ backgroundColor: 'white', maxWidth: '95%', minWidth: '30%' }}>
                        <SignUp formId={'create-account'} loading={loading} onSubmit={tryAccountCreation} />
                    </Box>
                </Grid>
            </Grid>
        </LoginTemplate>
    );
};

export default CreateAccount;
