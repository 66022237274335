//@ts-nocheck
import { Objective } from 'features/Objectives/types';
import { objective as api } from '_old/helpers/api';
import { AxiosError } from 'axios';

export const requestObjectives = () => {
    return {
        type: 'REQUEST_OBJECTIVES'
    };
};

export const receiveObjectives = (objectives: Object) => {
    return {
        type: 'RECEIVE_OBJECTIVES',
        payload: objectives
    };
};

export const updateObjective = (objective: Objective) => {
    return {
        type: 'UPDATE_OBJECTIVE',
        payload: objective
    };
};

export const removeObjective = (objective: Object) => {
    return {
        type: 'REMOVE_OBJECTIVE',
        payload: objective
    };
};

const reportError = ({ message }: { message: string }) => {
    // send the error to our logging service...
};

export const getObjectives = (id?: any) => {
    return async (dispatch: any) => {
        try {
            let o = await api.get(id);
            o = o.data;
            dispatch(receiveObjectives(o));
            return o;
        } catch (err) {
            throw err?.data.message;
        }
    };
};

export const createObjective = (objective: any) => {
    return async (dispatch: any, getStore: any) => {
        try {
            let o = await api.create(objective);
            o = o.data;
            dispatch(receiveObjectives([...Object.values(getStore().objectives.byID), o]));
            return o;
        } catch (err) {
            let e = err.data ? err.data.message : err;
            console.error(e);
            throw e;
        }
    };
};

export const editObjective = (objective: any) => {
    return async (dispatch: any) => {
        try {
            const { data } = await api.update(objective);
            console.log(data, 'updated objective');
            dispatch(updateObjective(objective));
        } catch (err) {
            let e = err.data ? err.data.message : err;
            console.error(e);
            throw e;
        }
    };
};

export const updateObjTags = async (tags: any, objID: any, newTag = false) => {
    try {
        return await api.updateTags(tags, objID, newTag ? newTag : false);
    } catch (err) {
        let e = err.data ? err.data.message : err;
        console.error(e);
        throw e;
    }
};
export const deleteObjective = (objective: any) => {
    return async (dispatch: any) => {
        try {
            await api.remove(objective);
            dispatch(removeObjective(objective));
        } catch (err) {
            if (err) {
                let e = err.data ? err.data.message || err.data : err;
                console.error(e);
                throw e;
            }
            throw new Error('Unable to delete');
        }
    };
};
