import React, { ReactNode } from 'react';
import { Box, Container, Stack } from '@mui/material';
// import MainTemplate from 'templates/MainTemplate';

const GenericTemplate: React.FC<{ Header?: React.FC }> = ({ Header, children }) => {
    return (
        <Stack spacing={2} component="main" sx={{ height: '100%', display: 'flex', justifyContent: "center", alignItems: "center", alignContent: "center" }}>
            {Header && <Header />}
            <Box sx={{ flexGrow: 1 }}> {/* This will grow to take up remaining space */}
                {children}
            </Box>
            {/* <Box component="main" flexGrow={1}>{children}</Box> */}
        </Stack>



    );
};

export default GenericTemplate;
