import axios from 'axios';

const BASE = '/api/v1/account/password-reset';

export async function resetPassword(username: string): Promise<void> {
    try {
        const { data } = await axios.get(`${BASE}?username=${username}`);
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw error;
        }
    }
}

export async function changePassword(token: string, body: { password: string; confirmPassword: string }): Promise<void> {
    try {
        const { data } = await axios.post(`${BASE}/${token}`, body);
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw error;
        }
    }
}
