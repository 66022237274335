// validationRules.ts
import * as zod from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import moment from 'moment';

const timeRegex = /^(1[0-2]|0?[1-9]):[0-5][0-9] [APap][mM]$/; //'HH:MM AM/PM' format (e.g., '8:00 AM' or '10:00 PM')
const nameRegex = /^[a-z ,.'-]+$/i
const passwordRegex = /^(?=.*[A-z])(?=.*[0-9])(?=.{7,})/
const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;

export const emailValidation = zod.string().email('Not a valid email address.');

export const passwordValidation = zod.string().min(7, 'Password must be at least 7 characters long.').max(32, 'Password must be less than 32 characters.').refine((val) => passwordRegex.test(val), {
  message: "Input must be at least 7 characters long and include both letters and numbers.",
});

export const titleValidation = zod.string().min(1, 'Please enter a title. This field is required.');

export const dateValidation= zod.any().refine(data => 
  data !== null && data !== '' && moment.isMoment(data) && data.isValid(), {
    message: 'Please select a date. This field is required.',
});

export const startTimeValidation= zod.any()
.refine((val) => typeof val === 'string' && val.trim().length > 0, {
  message: "Please select a start time. This field is required.",
})
.refine((val) => typeof val === 'string' && timeRegex.test(val), {
  message: "Invalid time format. Please use 'HH:MM AM/PM' format (e.g., '8:00 AM' or '10:00 PM')",
});

export const endTimeValidation= zod.any()
.refine((val) => typeof val === 'string' && val.trim().length > 0, {
  message: "Please select a end time. This field is required.",
})
.refine((val) => typeof val === 'string' && timeRegex.test(val), {
  message: "Invalid time format. Please use 'HH:MM AM/PM' format (e.g., '8:00 AM' or '10:00 PM')",
});

export const locationValidation = zod.string()

// This is for attendee fields that are in a string format.
export const attendeeValidation = zod.any().refine((data) => {

  if (typeof data !== 'object' || data === null) return false;

  return typeof data.label === 'string' && data.label.length > 0 &&
          typeof data.value === 'string' && data.value.length > 0;
}, {

  message: "Please select an attendee. This field is required.",
});

// This is for attendee fields that are in a bubble format.
export const attendeesValidation = zod.array(
  zod.object({
    label: zod.string(),
    value: zod.string(),
  })
).min(1, 'This field is required.');

export const textareaValidation = zod.string().min(1, 'Please enter your message in the text area.');

export const organizationValidation = zod.string().min(1, 'Please select an organization. This field is required.');

export const firstNameValidation = zod.string().min(1, 'This field is required.').refine((val) => nameRegex.test(val), {
  message: "Please use only letters and basic punctuation.",
});
export const lastNameValidation = zod.string().min(1, 'This field is required.').refine((val) => nameRegex.test(val), {
  message: "Please use only letters and basic punctuation.",
});

export const phoneNumberValidation = zod.string()
  .min(1, 'This field is required.')
  .refine((val) => phoneRegex.test(val), {
    message: "Invalid phone number format. Must be in format, e.g., (123) 456-7890."
});


export const streetValidation = zod.string()
  .min(1, 'This field is required.')
  .max(99, 'Street address must be less than 100 characters.');

export const cityValidation = zod.string()
  .min(1, 'This field is required.')
  .max(49, 'City name must be less than 50 characters.')
  .refine((val) => val === undefined || val.length === 0 || /^[a-zA-Z\s.,'-]+$/.test(val), {
    message: "City name can only contain letters and basic punctuation.",
  });

export const stateValidation = zod.string()
  .min(1, 'This field is required.')
  .max(49, 'State must be less than 50 characters.');

export const zipCodeValidation = zod.string()
.min(1, 'This field is required.')
.refine((val) => val === undefined || val.length === 0 || /^[0-9]{5}$/.test(val), {
  message: "Zip code must be exactly 5 digits.",
});