import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Loading from 'components/ui/atoms/Loading';
import { registerNotifications } from 'socket';
import { ReactNotifications } from 'react-notifications-component';
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import { Provider } from 'react-redux';
import { FC, useEffect } from 'react';
import store, { history } from 'store/store';
import Routes from 'routes';
import NotistackSnackbarProvider from 'components/ui/providers/noti-snackbar-provider';
import DateProvider from 'components/ui/providers/date-provider';
import ThemeProvider from 'components/ui/providers/theme-provider';

export default function () {
    useEffect(() => {
        if (window.Notification) {
            Notification.requestPermission(function (result) { }).then((r) => console.log(r));
        }
        registerNotifications(history);
        injectMouseflow();
    }, []);

    const injectMouseflow = () => {
        if (import.meta.env.MODE === 'production') {
            window._mfq = window._mfq || [];
            const mf = document.createElement('script');
            mf.type = 'text/javascript';
            mf.defer = true;
            mf.src = '//cdn.mouseflow.com/projects/f8ff3e93-1203-4652-a543-ccb6e7c8dfd8.js';
            document.getElementsByTagName('head')[0].appendChild(mf);
        }
    }
    return (
        <React.Suspense
            fallback={
                <div className="flex items-center justify-center w-screen h-screen">
                    <Loading />
                </div>
            }>
            {/* <ErrorBoundary> */}
            <ReactNotifications />
            <NotificationContainer />
            <ThemeProvider>
                <Provider store={store}>
                    <NotistackSnackbarProvider>
                        <DateProvider>
                            <Router>
                                <Routes />
                            </Router>
                        </DateProvider>
                    </NotistackSnackbarProvider>
                </Provider>
            </ThemeProvider>
            {/* </ErrorBoundary> */}
        </React.Suspense>
    );
}
