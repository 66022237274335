import React from 'react';
import { Button as MuiButton, ButtonProps as MuiProps } from '@mui/material';
import clsx from 'clsx';
import Loading from '../../atoms/Loading';



export type ButtonProps<C extends React.ElementType> = MuiProps<C, { component?: C }> & {
    loading?: boolean;
};

function BaseButton<C extends React.ElementType>(props: ButtonProps<C>) {
    // const classes = useLocalStyles();
    const { loading, className, children, disabled, ...p } = props;
    return (
        <MuiButton variant={'contained'} color={'primary'} {...p} component={props.component} disabled={disabled || loading} sx={{ position: 'relative' }} className={className}>
            {loading && <Loading size={24} />}
            {children}
        </MuiButton>
    );
}

export default BaseButton;
