import React from 'react';
import {ButtonProps} from "./BaseButton";
import BaseButton from './BaseButton';

export {BaseButton as Button}

type Props<C extends React.ElementType> = Omit<ButtonProps<C>, 'variant'> & {
    variant?: 'text' | 'outlined' | 'contained'
}

const Button = <C extends React.ElementType>({variant='contained', ...props}: Props<C>) => {
    return React.createElement(BaseButton, {variant, ...props});
}

export default Button;