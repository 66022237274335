import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, Action } from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';
import { ThunkDispatch } from 'redux-thunk';
import rootReducer, { RootState } from './reducers';
// import { api } from 'services/api';
import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import { isDevelopment } from '_old/helpers/utils';
import { emptySplitApi as rtkApi } from './slices/emptyApi';
export const history = createBrowserHistory();
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, null, Action<string>>;
// const logger:Middleware<{}, RootState> = store => next => action => {
//     console.group(action.type);
//     console.log('The action: ', action);
//     const returnValue = next(action);
//     console.log('The new state:', store.getState());
//     console.groupEnd();
//     return returnValue;
// }

// const initialState = {};
// const enhancers = []
// export const middleware = [
//   // logger,
//   thunk,
//   //routerMiddleware(history)
// ];

// if (process.env.NODE_ENV === 'development') {
//     const devToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()

//     if (typeof devToolsExtension === 'function') {
//         enhancers.push(devToolsExtension)
//     }
// }

export const createStore = ({ devTools, reducer, middleware, ...options }: ConfigureStoreOptions) =>
    configureStore({
        devTools: isDevelopment(),
        reducer: reducer ? reducer : rootReducer,
        middleware: middleware
            ? middleware
            : (getDefaultMiddleware) =>
                  getDefaultMiddleware({
                      immutableCheck: false, // TODO: need to check for mutable objects such as moment being used in redux
                      thunk: true,
                      serializableCheck: false
                  }).concat(rtkApi.middleware),
        ...options
    });
// const composedEnhancers = composeWithDevTools(applyMiddleware(...middleware, ...api.middleware));

// const store = createStore(
//     rootReducer,
//     initialState,
//     composedEnhancers
// )
export type DispatchType = ThunkDispatch<RootState, void, Action>;
const store = createStore({
    reducer: rootReducer
});
export default store;
