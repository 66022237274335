import zod from "zod"
import * as validations from './validationRules';
import moment from "moment";

export const signUpSchema = zod.object({
    firstName: validations.firstNameValidation,
    lastName: validations.lastNameValidation,
    username: validations.emailValidation,
    password: validations.passwordValidation,
    confirmPassword: validations.passwordValidation
});

export const loginFormSchema = zod.object({
    username: validations.emailValidation,
    password: validations.passwordValidation
});

export const forgotPasswordSchema = zod.object({
    username: validations.emailValidation,
});

export const createEditMeetingSchema = zod.object({
    title: validations.titleValidation,
    meetingDate: validations.dateValidation,
    startTime: validations.startTimeValidation,
    endTime: validations.endTimeValidation,
    repeat: zod.object({ type: zod.string(),
        until: zod.any()})
    ,
    location: validations.locationValidation,
    attendees: validations.attendeesValidation
}).superRefine((r,ctx) => {
   if(r.repeat.type === ''){
    return true
   }
    if(r.repeat.type && !moment(r.repeat.until).isValid()){
        ctx.addIssue({
            code: zod.ZodIssueCode.custom,
            message: `test`,
            path: [`repeat.until`],
          });
    }
})

export const createObjectiveSchema = zod.object({
    objective: validations.textareaValidation,
    organization: validations.organizationValidation,
    attendees: validations.attendeeValidation,
    Assignedby: validations.attendeesValidation,
    meetingDate: validations.dateValidation
});

export const createNoteSchema = zod.object({
    title: validations.titleValidation,
    text: validations.textareaValidation
});

export const profileSchema = zod.object({
    firstName: validations.firstNameValidation,
    lastName: validations.lastNameValidation,
    phoneNumber: validations.phoneNumberValidation,
    address: zod.object({
        street: validations.streetValidation,
        city: validations.cityValidation,
        state: validations.stateValidation,
        zipCode: validations.zipCodeValidation
    })
});

export const resetUsernameCredentialsSchema = zod.object({
    userName: validations.emailValidation,
    newUserName: validations.emailValidation
});

export const resetPasswordCredentialsSchema = zod.object({
    oldPassword: validations.passwordValidation,
    newPassword: validations.passwordValidation,
    confirmPassword: validations.passwordValidation
});